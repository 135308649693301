#overlay, #topArea, #menubarStickyWrapper, #menubar, .sidebarArea, #titleArea, #titleBox, #gpsArea, #toolbarArea, #footerArea,
.selectionBox, body:not(.tocPage) #toc, #sticky-footer-push, #disclaimer, #drawerContainer, body#searchResults #additionalPageTitleContent, body#searchResults .moreActions, .floatThead-container, .goog-te-banner-frame {
  display: none;
}

html, .lineTitleLine, #toc .titleLink {
  background-image: none;
}

body.responsive {
  top: 0 !important;
}

div#resultsContainer, div#pageTitleContainer {
  /* Firefox print can't handle multi page flex boxes */
  display: block;
}

.responsive#searchResults div#main {
  /* Firefox print can't handle multi page flex boxes */
  width: inherit;
  min-height: inherit;
}

div.resultPaper {
  box-shadow: none;
  border-bottom: 1px solid #999;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}

#main, #content {
  margin: 0;
  padding-bottom: 0;
}


#googleTranslateWidget {
  position: absolute;
  right: 48px;
  top: 16px;
}

.para, .deftext {
  font-weight: 500;
}

.barTitle, .barTitle:hover {
  background-color: transparent;
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  padding-left: 0;
}

.titleNumber {
  padding-left: 0;
}

.barTitle .titleLink {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

#toc .titleLink {
  width: auto;
  padding-right: 0;
}

html .section_content {
  border: none;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#toc {
  border-bottom: none;
}

.litem_number, .defitem_number,
.content, .titleTitle, .titleNumber, .title .titleNumber, .title .titleTitle {
  color: #000;
}

#content {
  font-size: 14px;
}

body#searchResults #content {
  font-size: 18px;
}

.content {
  line-height: 17px;
}

.litem, .defitem {
  margin: 12px 0;
}

#main table {
  margin: 0;
}

.level .wideTable.lightbox, .level .level .wideTable.lightbox, .level .level .level .wideTable.lightbox, .level .level .level .level .wideTable.lightbox, .level .level .level .level .level .wideTable.lightbox, .level .level .level .level .level .level .wideTable.lightbox {
  margin-left: 0;
}

.tableTitle:after {
  display: none;
}

.tableScrollWrapper {
  height: auto;
  overflow: visible;
  transition: none;
}

.landTable .tableScrollWrapper:after, .landTable .tableScrollWrapper:before {
  display: none;
}

.landTable .tableScrollWrapper > table {
  transform: none;
  width: auto;
}

.dashboardTitleButton {
  display: none;
}

.dashboardTitleDetail {
  clear: both;
}

.dashboardTitle {
  margin-top: 8px;
  padding-bottom: 2px;
}

#dayChart, #monthChart, #hourChart {
  margin-bottom: 0;
}

.dashboardSearchArea {
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
}

.dashboardSearchTitle {
  margin-top: -4px;
  margin-bottom: 12px;
}

.dashboardSearchNumbers li {
  padding-bottom: 0;
  margin-bottom: -2px;
}

.dashboardSearchEntry {
  padding-bottom: 1px;
  margin-bottom: 2px;
}

.dashboardInfoElementData, .dashboardTitleSub {
  color: #666;
}

#versionReturn {
  display: none;
}

#versionWarning, #warning {
  position: static;
  text-shadow: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#warnings div:last-child div:last-child {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.enhancedTable table, .enhancedTable td, .enhancedTable.frame,
.enhancedTable.defaultBorders th, .enhancedTable.defaultBorders td {
  border-color: lightgrey;
}

.enhancedTable th, .tableV2 th {
  font-weight: 600;
}

.enhancedTable.lightGrayHead th, .enhancedTable.migration th {
  background-color: lightgrey;
  color: black;
}

.enhancedTable.blackHead > table, .enhancedTable.blackHead th, .enhancedTable.blackHead td,
.enhancedTable.bordersOnly > table, .enhancedTable.bordersOnly th, .enhancedTable.bordersOnly td {
  border-color: black;
}

.enhancedTable.blackHead th {
  background-color: black;
  color: white;
}
